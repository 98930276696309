<template>
    <div class="flex justify-center items-center">
        <button
            class="w-full h-[50px] flex justify-center items-center bg-blue-500 text-white font-bold uppercase text-sm px-6 py-3 rounded-md outline-none "
            :class="{ 'bg-red-500 w-fit  cursor-not-allowed': buttonState === 'error', 'bg-green-500 ': buttonState === 'success' }" >

            <span v-if="buttonState === 'idle'" class="text-center" style="transition: all .15s ease">
                Marcar
            </span>

            <div v-if="buttonState === 'loading'" class="w-6 h-6 border-t-2 border-white rounded-full loader"></div>

            <span v-if="buttonState === 'success'" class="text-center animate-pop">
                Confirmado!
            </span>

            <p v-if="buttonState === 'error'" class="text-center">{{ buttonRequestMessage }}</p>

        </button>
    </div>
</template>
  
<script>
export default {
    emits: ['update-button-state'],
    props: {
        buttonState: {
            type: String,
            default: 'idle',
        },
        buttonRequestMessage: {
            type: String,
            default: '',
        }
    }
}
</script>
  
<style>
.loader {
    border-left-color: transparent;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.animate-pop {
    animation: pop 0.3s linear 1;
}

@keyframes pop {
    0% {
        transform: scale(0.1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}
</style>