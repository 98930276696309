<template>
    <div class="p-10 pt-20">
        <div class="flex flex-col md:flex-row text-center md:text-justify gap-5 items-center justify-center">
            <div class="md:w-1/2">
                <p class="text-white text-6xl text-center font-[Oswald] pb-10">Sobre Nós</p>
                <p class="text-white text-lg md:text-2xl text-center pb-10">Bem-vindo à nossa nova barbearia em Paredes, onde a
                    tradição encontra a excelência moderna! Estamos entusiasmados por termos finalmente aberto as nossas
                    portas ao público. Após cuidadosa preparação e dedicação, o nosso espaço está pronto para receber os
                    clientes com um ambiente acolhedor e sofisticado. Na nossa barbearia, o seu conforto e satisfação são a nossa prioridade. Seja para um corte de cabelo, um
                    tratamento de barba ou apenas para um cuidado personalizado, estamos aqui para superar as suas
                    expectativas. Venha conhecer a nossa nova barbearia e deixe-nos transformar a sua experiência em algo extraordinário. Estamos ansiosos para recebê-lo!
                </p>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'AboutComponent',
}
</script>
<style lang="">
    
</style>