
<template>
    <div class="coming-soon relative w-full h-full bg-cover bg-center flex flex-col justify-center items-center">

        <div @click="handleLogoClick" class="cursor-pointer">
            <img src="@/assets/logo_thick.svg" alt="Logo" class="w-[320px] md:w-[604px] mx-auto mt-10" />
        </div>

        <p class="text-white font-semibold text-md md:text-4xl uppercase">O seu estilo em primeiro lugar</p>

        <h1 class="text-center text-4xl md:text-5xl font-semibold text-white mt-10">Abre Brevemente</h1>

        <div class="countdown flex justify-center mt-4">
            <div class="countdown-part mx-2">
                <div class="number shadow-lg rounded w-20 text-center py-1">{{ days }} Dias</div>
            </div>
            <div class="countdown-part mx-2">
                <div class="number shadow-lg rounded w-20 text-center py-1">{{ hours }}h</div>
            </div>
            <div class="countdown-part mx-2">
                <div class="number shadow-lg rounded w-20 text-center py-1">{{ minutes }}m</div>
            </div>
            <div class="countdown-part mx-2">
                <div class="number shadow-lg rounded w-20 text-center py-1">{{ seconds }}s</div>
            </div>
        </div>

        <div class="text-center text-white text-md md:text-xl mt-10">
            <h1 class="font-semibold text-xl md:text-3xl">Morada:</h1>
            <p>Av. Vale do Sousa Edf. Garça Real II, Loja 1</p>
            <p>4580-040 Paredes</p>
        </div>

        <a href="https://instagram.com/focus_mens_salon?igshid=MzMyNGUyNmU2YQ==" target="_blank" class="mt-10">
            <img src="@/assets/instagram_white.svg" alt="Instagram" class="w-8 md:w-12" />
        </a>

        <div class="absolute bottom-2 text-white text-xs opacity-50">
            <p>&copy; Copyright 2023. All rights reserved.</p>
            <p>Made with <span class="text-red-500">&#10084;</span> by Paradox</p>
        </div>

    </div>
</template>
  
<script>
export default {
    name: 'ComingSoon',
    data() {
        return {
            logoClicks: 0,
            countdownTime: 0,
            releaseDate: new Date('2023-11-12T15:00:00')
        };
    },
    emits: ['disable-coming-soon'],
    methods: {
        handleLogoClick() {
            this.logoClicks++;
            if (this.logoClicks >= 10) {
                this.$emit('disable-coming-soon');
            }
        },
        tick() {
            if (this.countdownTime > 0) {
                setTimeout(() => {
                    this.countdownTime--;
                    this.tick();
                }, 1000);
            } else {
                this.setCountdownTime();
                this.tick();
            }
        },
        setCountdownTime() {
            const now = new Date();
            const timeDifference = this.releaseDate - now;
            this.countdownTime = timeDifference > 0 ? Math.floor(timeDifference / 1000) : 0;
        },
    },
    computed: {
        days() {
            return Math.floor(this.countdownTime / 86400);
        },
        hours() {
            return Math.floor((this.countdownTime % 86400) / 3600);
        },
        minutes() {
            return Math.floor((this.countdownTime % 3600) / 60);
        },
        seconds() {
            return this.countdownTime % 60;
        },
    },
    mounted() {
        this.setCountdownTime();
        this.tick();
    },
};
</script>
  
<style lang="postcss">
.coming-soon::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(42, 0, 53, 0.5);
    z-index: -1;
}

.coming-soon {
    background-image: url('@/assets/planet.jpg');
    z-index: 1;
}

.countdown-part .number {
    animation: bg-color-change 3s infinite;
}

@keyframes bg-color-change {

    0%,
    100% {
        background-color: #7869b4;
    }

    50% {
        background-color: #574b86;
    }
}
</style>