<template>
    <div class="w-full flex flex-col pt-20 items-center justify-between">
        <p class="text-white text-6xl text-center font-[Oswald] pb-10">Contactos</p>
        <div class="flex md:flex-row flex-col items-center gap-10">
            <div id="map" class="justify-start w-full h-[300px] md:w-[600px] md:h-[400px] z-0"></div>
            <div class="flex flex-col gap-11">

                <div class="text-left text-white text-xl md:text-2xl pl-10">
                    <h1 class="font-semibold text-2xl md:text-3xl">Morada:</h1>
                    <p>Av. Vale do Sousa Edf. Garça Real II, Loja 1</p>
                    <p>4580-040 Paredes</p>
                </div>
                <div class="text-left text-white text-xl md:text-2xl pl-10">
                    <h1 class="font-semibold text-2xl md:text-3xl">Telemóvel:</h1>
                    <p>+351 936 551 011</p>
                </div>
                <div class="text-left text-white text-xl md:text-2xl pl-10">
                    <h1 class="font-semibold text-2xl md:text-3xl">Horário:</h1>
                    <p>Terça a Sábado: 9:30h - 19:00h</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import myIcon from '@/assets/icon.png';

export default {
    name: 'MapComponent',
    mounted() {

        const icon = L.icon({
            iconUrl: myIcon,
            iconSize: [35, 35],
            iconAnchor: [15, 35]
        });

        // Initialize the map
        const map = L.map('map', {
            dragging: false,
            scrollWheelZoom: false,
            doubleClickZoom: false,
        }).setView([41.20255623063789, -8.328964980944775], 80); // Set the initial coordinates and zoom level

        L.marker([41.20255623063789, -8.328964980944775], { icon: icon }).addTo(map);

        // Add the tile layer (e.g., OpenStreetMap)
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {

        }).addTo(map);
    }
}
</script>

<style lang="">
    
</style>