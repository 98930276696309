<template>
  <ComingSoon v-if="showComingSoon" @disable-coming-soon="showComingSoon = false" />
  <router-view v-if="!showComingSoon" class="relative flex-1" @show-appointment="toggleAppointmentVisibility"
    @hide-appointment="hideAppointmentForm" :isScrolled='isScrolled'
    :showAppointmentPicker='showAppointmentPicker'></router-view>
  <FooterComp v-if="!showComingSoon" />
</template>

<script>
import FooterComp from '@/components/FooterComp.vue'
import ComingSoon from '@/components/ComingSoon.vue'

export default {
  name: 'App',
  components: {
    FooterComp,
    ComingSoon,
  },

  data() {
    return {
      showAppointmentPicker: false,
      isScrolled: false,
      showComingSoon: false,
    };
  },

  methods: {
    toggleAppointmentVisibility() {
      this.showAppointmentPicker = !this.showAppointmentPicker;
      document.body.classList.toggle("overflow-hidden");
    },
    hideAppointmentForm() {
      this.showAppointmentPicker = false;
      document.body.classList.remove("overflow-hidden");
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 600;
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

}
</script>

<style lang="postcss">
* {
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #101113;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #101113;
  @apply h-screen flex flex-col;
}

img {
  @apply pointer-events-none select-none;
}

.overflow-hidden {
  overflow: hidden;
}
</style>
