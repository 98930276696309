import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue'
import CancelAppointment from '@/views/CancelAppointment.vue'

const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/cancel-appointment',
      name: 'Cancel',
      component: CancelAppointment,
      props: (route) => ({ appointmentId: route.query.appointmentId }),
    }
  ]
  
  const router = createRouter({
    history: createWebHistory(),
    routes
  })

export default router