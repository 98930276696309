<template>
    <div>
        <img class="w-full" src="@/assets/border.png" alt="">
        <div class="w-full flex justify-center bg-white">
            <img class="w-[30%]" src="@/assets/test.gif" alt="">
        </div>
        <div class="flex gap-5 bg-white items-center justify-center h-12">
            <a class="h-full" href="https://instagram.com/focus_mens_salon?igshid=MzMyNGUyNmU2YQ==" target="_blank"><img
                    class="h-full" src="@/assets/instagram.svg" alt=""></a>
            <!-- <img class="h-full" src="@/assets/tiktok.svg" alt=""> -->
        </div>
        <p class="text-black text-4xl bg-white font-[Oswald] p-5">Focus - O seu estilo em primeiro lugar.</p>
        <div class="flex flex-col justify-between bg-white pb-3">
            <p class="flex-1">&copy; Copyright {{ currentYear }}. All rights reserved.</p>
            <p class="flex-1">Made with <span class="text-red-500">&#10084;</span> by Paradox</p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FooterComponent',
    data() {
        return {
            currentYear: new Date().getFullYear()
        };
    },
}
</script>

<style lang="">
    
</style>