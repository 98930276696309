<template>
    <div class="header-bg">
        <div class="header-bg-parallax" ref="parallax"></div>
        <div class="flex flex-col justify-end h-full w-full pb-[8rem] pl-6 ">
            <div class="w-fit p-4 rounded-xl bg-gradient-to-r from-black to-transparent ">
                <p class="font-['Oswald'] text-white text-6xl text-left text-shadow-p">FOCUS no seu visual,<br> nós tratamos do resto.</p>
                <div class="text-white text-left text-2xl mt-4">
                    <div class="flex items-center">
                        <img class="w-10 mr-4" src="@/assets/location.svg" alt="">
                        <div class="text-shadow-p flex-1">
                            <p>Av. Vale do Sousa Edf. Garça Real II, Loja 1</p>
                            <p>4580-040 Paredes</p>
                        </div>
                    </div>
                    <div class="flex flex-1 items-center pt-2 text-shadow-p ">
                        <img class="w-10 mr-4" src="@/assets/contact.svg" alt="">
                        <p>+351 936 551 011</p>
                    </div>
                </div>
            </div>
        </div>
        <button
            class=" absolute bottom-0 right-0 bg-gradient-to-t from-[#EE613C] to-[#EE4445] md:w-[300px] md:py-8 py-4 px-4 flex items-center justify-center"
            @click="$emit('show-appointment')">
            <img src="@/assets/scissors.svg" alt="" class="h-10">
            <p class="text-white font-bold text-2xl ml-2">AGENDA AGORA</p>
        </button>
    </div>
</template>

<script>


export default {
    name: 'HeaderComponent',
    emits: ['show-appointment'],

    mounted() {
        window.addEventListener("scroll", this.scrollLoop);
    },

    methods: {
        setTranslate(yPos) {
            const el = this.$refs.parallax;
            if (el && el.style) el.style.transform = "translate3d(0px," + yPos + "px, 0)";
        },

        scrollLoop() {
            this.setTranslate(window.scrollY * -0.2);
        }
    }

}
</script>


<style lang="postcss">
.header-bg {
    @apply h-[700px] relative z-0 overflow-hidden;
}

.header-bg::after {
    content: '';
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.header-bg-parallax {
    background-image: url(@/assets/14.png);
    background-position: 50%;
    z-index: -1;
    @apply bg-cover bg-no-repeat absolute top-0 w-full h-[125%];
}

.text-shadow-p {
    text-shadow: 2px 2px 2px #000000;
}
</style>