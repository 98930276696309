<template>
    <div>
        <NavBar @show-appointment="toggleAppointmentVisibility" :isScrolled='isScrolled' />
        <HeaderComp @show-appointment="toggleAppointmentVisibility" />
        <AboutComp ref="aboutComp" />
        <BarbersComp/>
        <ServicesComp ref="servicesComp" />
        <AppointmentComp v-show="showAppointmentPicker" class="overlay-component" @hide-appointment="hideAppointmentForm"/>
        <imageGalleryComp />
        <ContactsComp ref="contactsComp" />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import HeaderComp from '@/components/HeaderComp.vue'
import AboutComp from '@/components/AboutComp.vue'
import AppointmentComp from '@/components/AppointmentComp.vue'
import BarbersComp from '@/components/BarbersComp.vue'
import ContactsComp from '@/components/ContactsComp.vue'
import ServicesComp from '@/components/ServicesComp.vue'
import imageGalleryComp from '@/components/imageGalleryComp.vue'

export default {
    name: 'Home',
    emits: ['show-appointment','hide-appointment'],
    props: ['showAppointmentPicker', 'isScrolled'],
    components: {
        NavBar,
        HeaderComp,
        AboutComp,
        BarbersComp,
        AppointmentComp,
        ContactsComp,
        ServicesComp,
        imageGalleryComp,
    },

    methods: {
        hideAppointmentForm() {
            this.$emit('hide-appointment');
        },
        toggleAppointmentVisibility() {
            this.$emit('show-appointment');
        }
    }
}
</script>
<style lang="postcss">

    .overlay-component {
        position: fixed;
        z-index: 20;
        overflow: auto;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        background: rgba(0, 0, 0, 0.9);
        padding: 10px;
    }
</style>