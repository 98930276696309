<template>
    <div class="flex flex-col w-[90%] md:w-[50%] items-center justify-center mx-auto -mb-10">
        <p class="text-white text-6xl text-center font-[Oswald]">A Arte do Corte</p>
        <p class="text-white text-3xl text-center font-light py-5">Descubra os Nossos Talentosos
            Barbeiros</p>
        <div class="w-full flex flex-col md:flex-row ">
            <div class="w-full flex flex-col items-center relative">
                <div class="absolute flex flex-col items-center w-1/2 aspect-square top-32">
                    <div
                        class="w-[80%] h-[80%] bg-[url('@/assets/beto.jpg')] bg-white bg-contain bg-center bg-no-repeat border rounded-md">
                    </div>
                    <p class="text-[#EE4445] text-4xl font-[Oswald] font-bold drop-shadow-md pt-5">BETO ALMEIDA</p>
                </div>
                <img src="@/assets/barber-chair.png" alt="" class="w-full">
            </div>
            <div class="w-full flex flex-col items-center relative opacity-30 blur-[3px]">
                <div class="absolute flex flex-col items-center w-1/2 aspect-square top-32">
                    <div
                        class=" w-[80%] h-[80%] bg-[url('@/assets/user-placeholder.jpg')] bg-contain bg-center bg-no-repeat border rounded-md">
                    </div>
                    <p class="text-white text-4xl font-[Oswald] font-light pt-5">?????</p>
                </div>
                <img src="@/assets/barber-chair.png" alt="" class="w-full">
            </div>
        </div>
    </div>
</template>


<script>


export default {
    name: 'BarbersComponents',
    data() {
        return {

        }
    },
}
</script>

<style lang="postcss">
.reveal {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}

.reveal.active {
    transform: translateY(0);
    opacity: 1;
}
</style>