<template>

  <!-- :class="{ 'bg-black fixed opacity-1 my-transition': isScrolled, 'absolute': sendToTop, 'bg-transparent': !isScrolled, 'fixed opacity-0 my-transition': !isScrolled && !sendToTop }"> -->
  <!--  -->
  <nav class="nav-bar"
  :class="{'fixed bg-black': isMobile, 'px-6 py-5': !isMobile, 'fixed bg-black my-transition': !isMobile && (isScrolled || isMenuAnimating), 'absolute': !isMobile && (!isScrolled && !isMenuAnimating), 'opacity-0': !isMobile && (!isScrolled && isMenuAnimating)}">
    <img src="@/assets/logo_thick.svg" class=" px-4 py-1 w-[140px]" :class="{'p-0': !isMobile}" alt="">
    <button v-if='isMobile' class="py-3 pr-4 pl-2" :class="{ 'open': isMobile && open }" @click="clickMenuHandler" id="menu-btn">
      <div class="menu-btn--span-container">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
    <div class="font-['Montserrat'] justify-center opacity-transition"
      :class="{'absolute w-full left-0 pt-10 -z-10 top-0': isMobile, 'top-0': isMobile && open, 'flex items-center': !isMobile}">
      <div class="options-container">
        <ul
        :class="{'w-full h-fit space-y-2 relative pt-6 bg-black': isMobile, 'animate-menu-in': isMobile && open, 'animate-menu-out': isMobile && !open && isMenuAnimating, 'hidden': isMobile && !open && !isMenuAnimating, 'pt-0 bg-transparent flex m-0 items-center': !isMobile}">
          <li> 
            <a href="#" class="text-white" :class="{ 'mr-6 ': !isMobile }"
              @click.prevent="scrollTo('aboutComp'); closeNavMenu()">Sobre Nós</a>
          </li>
          <li>
            <a href="#" class="text-white" :class="{ 'mr-6 ': !isMobile }"
              @click.prevent="scrollTo('servicesComp'); closeNavMenu()">Serviços</a>
          </li>
          <li>
            <a href="#" class="text-white" :class="{ 'mr-6 ': !isMobile }"
              @click.prevent="scrollTo('contactsComp'); closeNavMenu()">Contactos</a>
          </li>
          <li>
            <button type="button" class="bg-gradient-to-t from-[#EE613C] to-[#EE4445] p-2 rounded-md text-white font-bold"
              :class="{ 'w-full rounded-none mt-4': isMobile }" @click="$emit('show-appointment'), closeNavMenu()">Agenda Agora</button>
          </li>
          <!-- <span v-if="open" class="absolute -bottom-10 left-0 bg-gradient-to-b from-black from-5% h-10 w-full"> </span> -->
        </ul>
      </div>
    </div>

  </nav>
</template>
  
<script>
export default {
  name: "NavBar",
  props: ['isScrolled'],
  emits: ['show-appointment'],
  data() {
    return {
      open: false,
      isMobile: window.innerWidth < 768,
      isMenuAnimating: false,
    }
  },

  created(){
    window.addEventListener("resize", this.resizeHandler)
  },

  methods: {
    clickMenuHandler() {
      this.isMenuAnimating = true;
      setTimeout(() => {
        this.isMenuAnimating = false;
      }, 350);
      this.open = !this.open;
    },

    resizeHandler() {
      this.isMobile = window.innerWidth < 768;
    },

    scrollTo(ref) {
      this.$parent.$refs[ref].$el.scrollIntoView({ behavior: 'smooth' });
    },
    closeNavMenu() {
      this.open = false;
    },
    handleScrollHalfWay() {
      return window.scrollY > 200;
    },
    
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  watch: {
    isScrolled: function () {
      if (!this.isMobile) {
        this.isMenuAnimating = true;
        setTimeout(() => {
          this.isMenuAnimating = false;
        }, 350);
      }
    }
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>
  
<style scoped lang="postcss">
.nav-bar {
  @apply w-full flex justify-between items-center top-0 left-0 z-10;
}

.my-transition {
  transition: all 350ms ease;
}

/* // class for animation elemts
// final: fixed */


/* .nav-bar.is-scrolled {
  @apply bg-black fixed;
} */

.options-container {
  overflow: hidden;
}

.opacity-transition {
  transition: background-color 0.5s ease;
}

@keyframes slideInDown {
  0% {
    /* display: block; */
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutUp{
  0% {
    transform: translateY(0%);
    opacity: 1;
  }

  99% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    display: hidden;
  }
}

.animate-menu-in {
  animation: slideInDown 0.5s ease;
}
.animate-menu-out {
  animation: slideOutUp 0.5s ease;
}

#menu-btn {
  @apply py-3 pr-4 pl-2 outline-none;
}
.menu-btn--span-container {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    @apply w-5 h-4 px-2 relative cursor-pointer;
  }

#menu-btn span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

#menu-btn span:nth-child(1) {
  top: 1px;
}

#menu-btn span:nth-child(2),
#menu-btn span:nth-child(3) {
  top: 7px;
}

#menu-btn span:nth-child(4) {
  top: 13px;
}

#menu-btn.open span:nth-child(1) {
  top: 7px;
  opacity: 0;
}

#menu-btn.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#menu-btn.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#menu-btn.open span:nth-child(4) {
  top: 6px;
  opacity: 0;
}
</style>